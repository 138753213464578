@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

.css-mhc70k-MuiGrid-root {
  height: 100vh !important;
  margin-top: 0px !important;
}

.MuiGrid-item {
  padding-top: 0px !important;
  padding-left: 0px !important;
}

.css-1aqdo83 {
  display: grid;
  gap: 24px;
  width: 350px;
  margin-top: 170px !important;
}


::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #313131;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #313131;
}

.MuiButtonBase-root.MuiButton-root {
  color: rgba(0, 0, 0, 0.87) !important;
}

.css-zow5z4-MuiGrid-root {
  margin-top: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}

.css-kr82s3-MuiTypography-root {
  color: inherit !important;
}